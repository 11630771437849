
@import "variables";

@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&subset=cyrillic");@import url("../fonts/font-awesome-4.7.0/css/font-awesome.min.css");html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
article, aside, details, figcaption, figure, footer, header, menu, nav, section{display:block;}
body{line-height:1;}
blockquote, q{quotes:none;}
blockquote:before, blockquote:after, q:before, q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
.clearfix:after{content:"";display:table;clear:both;}
html{height:100%;}
body{background-color:#fff;color:#111;font-size:15px;font-family:"Ubuntu", sans-serif;font-weight:400;height:100%;}
.black{color:#111;}
.wrap{width:98%;margin:0 auto;-webkit-box-sizing:border-box;box-sizing:border-box;position:relative;}
.mrg-top {margin-top: 30px;}
.content-in .wrap{width:90%;}
.content-in p{margin-bottom:10px; line-height: 18px;}
.content-in a {text-decoration: underline;}
.content-in a:hover {text-decoration: none;}
.content-in h2, .content-in h3, .content-in h4, .content-in h5, .content-in h6{margin-top:30px;}
.content-wide p{margin-bottom:10px;}
.content-wide h2, .content-wide h3, .content-wide h4, .content-wide h5, .content-wide h6{margin-top:30px;}
a{color:#111;-webkit-transition:all .25s;transition:all .25s;text-decoration:none;}
a:hover, a:focus{color:#c73939;}
a img:hover, a img:focus{-webkit-filter:contrast(110%);filter:contrast(110%);}
img{max-width:100%;height:auto;}
h1, h2, h3, h4, h5, h6{font-family:"Ubuntu", sans-serif;font-weight:700;text-align:center;margin-bottom:20px;}
h1{font-size:30px;}
h1 small{font-size:25px;font-weight:300;display:block;padding-top:10px;}
h2{font-size:25px;}
h2 small{font-size:20px;font-weight:300;display:block;padding-top:10px;}
h3{font-size:22px;}
h4{font-size:20px;}
h5{font-size:18px;}
h6{font-size:16px;}
.err404{-webkit-box-sizing:border-box;box-sizing:border-box;position:relative;}
.err404 h1{z-index:3;position:absolute;top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);width:100%;}
.err404 h1 a{display:block;text-decoration:underline;margin-top:20px;}
.err404 h1 a:hover{text-decoration:none;}
.btn-link{height:48px;padding:0 50px;line-height:48px;color:#fff;background-color:transparent;font-family:"Ubuntu", sans-serif;font-size:14px;display:inline-block;text-transform:uppercase;border:1px solid #fff;}
.btn-link:hover, .btn-link:focus{background-color:#fff;color:#c73939;}
.lft{float:left;}
.rght{float:right;}
.header{background:#fff;width:100%;padding:10px 3%;-webkit-box-sizing:border-box;box-sizing:border-box;}
.header img{max-width:75%;}
.logo{display:block;float:left;z-index:5;}
#open-menu{background:transparent none repeat scroll 0 0;border-bottom:18px double #111;border-top:6px solid #111;cursor:pointer;display:block;height:6px;width:50px;z-index:5;position:fixed;top:25px;right:20px;-webkit-filter:drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));filter:drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));}
#open-menu:hover{border-color:#c73939;}
.menu{display:none;background:#fff;position:fixed;width:100%;left:0;top:0;text-align:center;padding:20px;-webkit-box-sizing:border-box;box-sizing:border-box;z-index:4;-webkit-filter:drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));filter:drop-shadow(0 2px 2px rgba(0, 0, 0, 0.2));}
.menu:before{content:"";height:0;width:0;display:block;border-top:25px solid #fff;border-bottom:25px solid transparent;border-right:123px solid transparent;border-left:123px solid transparent;position:absolute;bottom:-50px;left:50%;-webkit-transform:translate(-50%, 0);-ms-transform:translate(-50%, 0);transform:translate(-50%, 0);}
.menu li{list-style-type:none;display:block;position:relative;}
.menu li a{text-decoration:none;color:#111;padding:15px 30px;display:block;text-transform:uppercase;font-family:"Ubuntu", sans-serif;font-weight:500;font-size:20px;}
.menu li a:hover, .menu li a:focus{color:#c73939;}
.menu li.active a{color:#c73939;}
.bg-line{background-size:cover !important;padding:30px 0;color:#fff;position:relative;min-height:100px;}
.bg-line:after{content:"";width:100%;height:100%;position:absolute;left:0;top:0;background:rgba(0, 0, 0, 0.3);}
.bg-line .wrap{z-index:3;}
.bg-line a{color:#fff;}
.contacts{display:none;margin-bottom:60px;}
.contacts .connect{float:left;text-align:center;}
.contacts .adr{float:right;text-align:right;font-size:18px;font-weight:500;line-height:20px;}
.contacts .tel{display:block;font-size:27px;font-weight:700;margin-bottom:20px;}
.contacts .mail{display:block;}
.contacts .mail:hover, .contacts .mail:focus{text-decoration:underline;}
.soc{width:34px;height:34px;border:1px solid #fff;display:inline-block;border-radius:50%;text-align:center;line-height:34px;margin-left:5px;margin-bottom:20px;}
.soc:hover, .soc:focus{background:#fff;}
.soc:hover .fa, .soc:focus .fa{color:#c73939;}
.white-line{padding:40px 0;}
.blue-line{background-color:#e3f3f9;padding:40px 0;}
.ban{display:block;width:236px;margin:10px auto;border:1px solid #a8d3e3;text-align:center;-webkit-transition:none;transition:none;}
.ban:hover{background:#c73939;color:#fff;}
.ban:hover .img-frame:before{border-top:30px solid #c73939;}
.ban-ttl{font-size:18px;font-weight:500;display:block;padding:20px 10px 15px; text-decoration: none}
.ban .ban-txt{padding:10px;display:block;position:relative;}
.ban .img-frame{display:block;position:relative;}
.ban .img-frame:before{content:"";height:0;width:0;border-top:30px solid #e3f3f9;border-bottom:30px solid transparent;border-left:118px solid transparent;border-right:118px solid transparent;left:0;top:0;position:absolute;z-index:2;}
.ban img{display:block;}
.order{background:rgba(0, 0, 0, 0.4);padding:15px 15px 0;max-width:400px;margin:auto;}
.process{color:#fff;font-size:20px;font-weight:500;}
.process .stage{text-align:center;padding:140px 30px 40px;position:relative;}
.process .stage svg{position:absolute;z-index:2;}
.process .stage .arr-right{display:none;}
.process .stage .arr-down{width:100%;height:30px;left:0;bottom:-29px;display:block;}
.process .stage + .stage{padding-top:160px;}
.process .click{background:#b63426;}
.process .click .stage-ttl:before{background:url(../images/design/icons.png) 50% 0 no-repeat;}
.process .click svg{fill:#b63426;}
.process .search{background:#f0512a;}
.process .search .stage-ttl:before{background:url(../images/design/icons.png) 50% -70px no-repeat;}
.process .search svg{fill:#f0512a;}
.process .doc{background:#f38129;}
.process .doc .stage-ttl:before{background:url(../images/design/icons.png) 50% -140px no-repeat;}
.process .doc svg{fill:#f38129;}
.process .relax{background:#faa94e;}
.process .relax .stage-ttl:before{background:url(../images/design/icons.png) 50% -210px no-repeat;}
.process .stage-ttl{position:relative;}
.process .stage-ttl:before{content:"";width:100%;height:70px;display:block;position:absolute;top:-100px;left:0;}
.partners{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-justify-content:space-around;-ms-flex-pack:distribute;justify-content:space-around;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;padding:0 10px;}
.partner{list-style-type:none;display:inline-block;background-color:rgba(255, 255, 255, 0.7);padding:20px;width:45%;margin-bottom:10px;-webkit-box-sizing:border-box;box-sizing:border-box;background-position:50% 50%;background-repeat:no-repeat;background-size:contain;border:5px solid rgba(255, 255, 255, 0);}
.map{position:relative;}
.map iframe{width:100%;height:300px;position:relative;}
.foot-cont{background:rgba(0, 0, 0, 0.6);padding:20px;color:#fff;text-align:center;}
.foot-cont a{color:#fff;}
.foot-cont .tel{font-size:20px;font-weight:700;display:block;margin:30px 0 20px;}
.foot-cont .mail{display:block;margin:20px 0;font-size:18px;}
.foot-cont .mail:hover{text-decoration:underline;}
.foot-cont .btn-link{margin:20px auto;}
.foot-cont .soc{height:40px;width:40px;line-height:40px;margin:0 5px;}
.copyright{font-weight:300;font-size:14px;margin-top:30px;}
.copyright img{display:none;}
#top{display:none;width:30px;height:30px;font-size:20px;border-radius:50%;background:#c9c9c9;position:fixed;bottom:20px;right:20px;color:#fff;text-align:center;line-height:26px;z-index:5;}
#top:hover{cursor:pointer;background:#b9b9b9;}
.callback{width:250px;height:360px;}
.flag-block {
    text-align: center;
    .flag-itm {
        margin-bottom: 20px
    }
}
.breadcrumbs {
    margin-bottom: 20px;
     color: #555;
    li {
        list-style-type: none;
        display: inline;
        font-size: 80%;
        a {
            color: #555;
            &:hover {
                color: $second-color;
            }
        }
        &+li {
            &:before {
            content: " > "
                }
        }
        }
}
@media screen and (min-width:641px){.contacts{display:block;}
.ban-line{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-align:stretch;-webkit-align-items:stretch;-ms-flex-align:stretch;align-items:stretch;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;}
.ban-line .ban{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.partner{width:24%;}
#top{width:80px;height:80px;line-height:70px;font-size:60px;}
.callback{width:400px;height:360px;}
    .flag-block {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .flag-itm {
            width: 45%
        }
    }
}
@media screen and (min-width:801px){.content-in .wrap{width:80%;}
h1{font-size:45px;}
h1 small{font-size:35px;}
h2{font-size:40px;}
h2 small{font-size:34px;}
.order{float:right;padding:45px 45px 0;}
.order-block h2{float:left;text-align:left;width:50%;margin-top:30px;line-height:40px;}
.order-block h2 small{padding-top:30px;}
.header{padding:10px 0 0;}
.logo{float:none;position:relative;text-align:center;}
#open-menu{display:none;}
.menu{display:block;background:transparent;position:relative;width:100%;-webkit-filter:none;filter:none;padding:0 0 20px;}
.menu .left-menu, .menu .right-menu{display:inline-block;}
.menu .left-menu a, .menu .right-menu a{padding:0 10px;}
.menu li{display:inline-block;line-height:40px;height:40px;position:relative;}
.menu li a{font-size:15px;}
.menu li a:hover, .menu li a:focus{color:#c73939;}
.menu li a:hover:after, .menu li a:focus:after{content:"";background:#c73939;display:block;width:102%;height:2px;position:relative;right:1%;}
.process{width:70%;margin:auto;}
.partners{margin:50px 0 60px;}
.partner{border:10px solid rgba(255, 255, 255, 0);}
.map iframe{height:400px;}
.map .foot-cont{position:absolute;top:0;right:0;height:100%;-webkit-box-sizing:border-box;box-sizing:border-box;padding:30px 40px;text-align:left;}
.map .foot-cont .tel{font-size:30px;}
.map .foot-cont h2{text-align:left;font-size:35px;}
.map .copyright{margin-top:20px;}
.ban{width:154px;}
.ban .img-frame:before{border-width:20px 77px;}
}
@media screen and (min-width:1025px){.wrap{width:980px;}
.content-in .wrap{width:800px;margin-bottom:60px;}
.header img{max-width:100%;}
.logo{position:absolute;left:50%;-webkit-transform:translate(-50%, 0);-ms-transform:translate(-50%, 0);transform:translate(-50%, 0);text-align:center;padding-left:15px;}
.menu{padding:20px 0 30px;}
.menu .left-menu{float:left;}
.menu .left-menu a{padding:0 20px 0 0;}
.menu .right-menu{float:right;}
.menu .right-menu a{padding:0 0 0 20px;}
.process{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;width:916px;margin:auto;}
.process .stage{width:25%;padding:140px 20px 30px 30px;}
.process .stage + .stage{padding-top:140px;}
.process .stage .arr-down{display:none;}
.process .stage .arr-right{display:block;top:0;right:-29px;width:30px;height:100%;}
.process .stage + .stage{padding-left:50px;}
.partner{border:20px solid rgba(255, 255, 255, 0);}
.map iframe{height:500px;}
.map .foot-cont{padding:45px;min-width:40%;}
.map .foot-cont h2{font-size:40px;}
.map .foot-cont .tel{font-size:35px;}
.map .copyright{position:absolute;bottom:30px;left:45px;}
.map .copyright img{display:block;float:right;margin-left:30px;}
.ban{width:194px;}
.ban .img-frame:before{border-width:30px 97px;}
    .flag-block {
        .flag-itm {
            width: 24%
        }
    }
}
@media screen and (min-width:1281px){.wrap{width:1200px;}
.ban{width:236px;}
.ban .img-frame:before{border-width:30px 118px;}
}